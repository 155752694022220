import React from "react"
import styled from "@emotion/styled"
import MediaRow from "../images/media-row.png"

const Col = styled.div`
  @media (max-width: 766px) {
    overflow: scroll;
  }
  img {
    @media (min-width: 767px) {
      max-width: 100%;
    }
  }
`

export default () => (
  <section className="bg-light py-5">
    <div className="container">
      <div className="row">
        <Col className="col">
          <img src={MediaRow} alt="Tembo Education has been featured in" />
        </Col>
      </div>
    </div>
  </section>
)
