import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Hero from '../components/hero'
import Cta from '../components/cta'
import Features from '../components/features'
import Testimonials from '../components/testimonials'
import Process from '../components/process'
import MediaRow from '../components/media-row'


export default ({ data }) => (
    <Layout 
    isLanding={true}
    title="SMS Early Childhood Education"
    description="Innovative daily learning for ages 0-6 sent via SMS and WhatsApp. Get your sample activity and learn how Tembo Education can maximize your child's potential."
    >
      <Hero />
      <Cta />
      <MediaRow />
      <Features />
      <Process />
      <Testimonials />
    </Layout>
  )

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
