import React from "react"
import { css } from "@emotion/core"
import TimeBlue from "../images/feature__convenient_cloud.svg"
import NoScreen from "../images/feature__no_screen_time__phone.svg"
import Scientist from "../images/feature__expert__cap.svg"

const features = [
  {
    title: "Convenient",
    text:
      'We know how busy you are, which is why our activities are designed to be completed in just **15 minutes**.',
    img: TimeBlue,
    alt: "Time image",
  },
  {
    title: "No Screen Time",
    text:
      'Tembo is **not another app**; we deliver activities through the services you already use: **SMS** or **WhatsApp**. We believe learning should be a chance to bond with a child, not just stare at a screen. We want to create memories that last a lifetime through learning.',
    reverse: true,
    img: NoScreen,
    alt: "No screen image",
  },
  {
    title: "Expertly Designed",
    text:
      'Our **evidence-based activities** are based on research by **Harvard** Center on the Developing Child, PBS, Lego Foundation and Pearson. Our curriculum covers all four domains of learning: **cognitive**, **motor**, **language**, and **social/emotional**.',
    img: Scientist,
    alt: "Scientist image",
  },
]
const featureStyle = css`
  .feature-img {
    @media (max-width: 575px) {
      max-width: 230px;
    }
    max-height: 291px;
    // box-shadow: 8px 8px 0 rgba(38,170,226,.2);
    // border: rgba(38, 170, 226, 0.6) 2px solid;
    // padding: 1rem;
  }

  .feature-header {
    font-size: 3rem;
    @media (min-width: 768px) {
      font-size: 4rem;
    }
  }
  .feature-text {
    font-size: 1.25rem;
    letter-spacing: 0.1px;
    line-height: 1.7;
    font-weight: 300;
    @media (min-width: 768px) {
      font-size: 1.6rem;
    }
  }
`


function convertFeatureText(text) {
  return text
    .replace(/(?:\*+)(.*?)(?:\*+)/g, ($1, $2, $3) =>
    `<span class="font-weight-bold">${$2}</span>`
    )
}

const Feature = ({ title, text, reverse, img, alt }) => {
  const paddingDirection = reverse ? 'pr' : 'pl'
  return (
    <div className="col-12 py-5" css={featureStyle}>
      <div className={`row align-items-center justify-content-center ${reverse ? 'flex-sm-row-reverse' : 'flex-sm-row'}`}>
        <div className="col-xs-12 col-sm-6 col-lg-4">
          <img src={img} alt={alt} className="feature-img img-fluid mb-5 mb-sm-0 rounded" />
        </div>
        <div className={`col-xs-12 col-sm-6 ${paddingDirection}-md-4 ${paddingDirection}-lg-5`}>
          <h3 className="text-primary feature-header font-weight-light">{title}</h3>
          <div className="feature-text" dangerouslySetInnerHTML={{__html: convertFeatureText(text)}} />
        </div>
      </div>
    </div>
  )
}

export default () => (
  <section className="pb-5">
    <div className="container">
      <div className="row align-items-center justify-content-center">
        {features.map(feature => <Feature {...feature} key={feature.text} /> )}
      </div>
    </div>
  </section>
)
