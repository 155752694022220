import React from "react"
import { css } from "@emotion/core"
import CurlyQuote from "../images/quotes-left.svg"

const quotes = [
  {
    quote:
      "It enables me to spend quality time with my child, instead of handing over a smartphone or tablet.",
    author: "Joseph",
  },
  {
    quote:
      "My daughter enjoys learning now, more than ever before. With Tembo, my daughter always wants to learn.",
    author: "Esther",
  },
]

const quoteStyle = css`
  position: relative;
  box-shadow: 8px 8px 0 rgba(38,170,226,.2);
  border: rgba(38, 170, 226, 0.6) 2px solid;
  @media (max-width: 575px) {
    max-width: 93%;
  }
  .quote-quote {
    fill: #fff;
    path {
      fill: inherit;
    }
  }
  .quote-author {
    font-size: 1.9rem;
    font-weight: 600;
  }
  .quote-text {
    font-size: 1.25rem;
    line-height: 1.7;
    font-weight: 400;
    @media (min-width: 768px) {
      font-size: 1.6rem;
    }
  }
`

const Quote = ({ count, author, quote }) => {
  return (
    <div
      className = {
          `col-xs-11 col-md-5 col-lg-4 bg-white p-3 rounded text-primary ${
        count === 0 ? "" : "mt-4 mt-md-0 ml-md-5"
      }`}
      css={quoteStyle}
      key={quote}
    >
      <img src={CurlyQuote} alt="left quote" className="quote-quote" />
      <div className="quote-text my-3">{quote}</div>
      <div className="quote-author">{author}</div>
    </div>
  )
}

export default () => (
  <section className="py-5 bg-light">
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col-12 text-center">
          <div className="text-primary display-text">What Parents Say</div>
        </div>
        <div className="col-12">
          <div className="row align-items-top justify-content-center py-5">
            {quotes.map((x, i) => (
              <Quote
                count={i}
                author={x.author}
                quote={x.quote}
                key={x.quote}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
)
