import React from "react"
import styled from "@emotion/styled"
import WaIcon from '../images/whatsapp-brands.svg'
import { temboBlue, signupLink } from "../utils/constants";

const LeadText = styled.p`
  color: #49b6e4;
  font-weight: 300;
  margin: 2rem 0;
  font-size: 1.5rem;
  @media (min-width: 630px) {
    font-size: 1.7rem;
  }
  @media (min-width: 768px) {
    font-size: 1.9rem;
  }
  @media (min-width: 1200px) {
    font-size: 2.2rem;
  }
`

const DisplayText = styled.h1`
  font-weight: 300;
  font-size: 3.3rem;
  @media (min-width: 630px) {
    font-size: 3.5rem;
  }
  @media (min-width: 768px) {
    font-size: 4rem;
  }
  @media (min-width: 1200px) {
    font-size: 5.5rem;
  }
`

const CustomJumbotron = styled.section`
margin-bottom: 0 !important;
padding-bottom: 3rem;
@media (min-width: 568px) {
  margin-bottom: 0 !important;
  padding-bottom: 6rem;
}
`

const WhatsAppIcon = styled.i`
background: url(${WaIcon}) no-repeat;
height: 20px;
display: inline-block;
width: 20px;
color: ${temboBlue};
@media (min-width: 768px) {
  height: 25px;
  width: 25px;
}
`
export default () => (
  <CustomJumbotron className="jumbotron bg-light">
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col-xs-12 col-lg-9 text-center">
          <DisplayText className="text-primary">
            Screen Time-Free Learning for Ages <span className="d-inline-block">0-6</span>
          </DisplayText>
          <LeadText>
            Help your child grow—and forge closer parent-child bonds—in just <span className="font-weight-bold">15
            minutes</span> a day with parent-led activities delivered by SMS/text or{" "}
           <span className="font-weight-bold d-inline-block"><WhatsAppIcon />WhatsApp</span>!
          </LeadText>
          <div className="mt-4">
            <a
            href='https://beta.temboeducationgroup.com/register'
            className="btn btn-lg btn-primary mx-auto"
            css={{
              fontSize: "21px",
              padding: "20px 78px",
              borderRadius: "50px",
            }}
            >
              Sign Up
            </a>
          </div>
        </div>
      </div>
    </div>
  </CustomJumbotron>
)
