import React, { useState, Fragment } from "react"
import { css } from "@emotion/core"
import PhoneInput from "react-phone-input-2"
import axios from "axios"
import { temboApiSite } from "../utils/constants"
import { submitGtagEvent } from "../utils/helpers"
import FormModal from "./form-modal"
import 'react-phone-input-2/dist/style.css'

const ctaStyle = css`
  .cta-tel-input {
    font-size: 16px !important;
    height: 50px !important;
    width: 100% !important;
  }
  .react-tel-input {
    .flag-dropdown {
      background-color: white !important;
    }
  }

  .trigger-modal:focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5),
      0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
`

const SampleForm = () => {
  const [formState, setFormState] = useState({
    phone: "",
    age: 0,
    delivery: "whatsapp",
    captcha: null
  })
  const [showModal, setShowModal] = useState(false)
  const [blockSubmit, setBlockSubmit] = useState(false)
  const [smsOption, setSmsOption] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [isWhatsapp, setIsWhatsapp] = useState(false)
  const [country, setCountry] = useState('')
  const [dialCode, setDialCode] = useState('');
  const [valid, setValid] = useState(false);
  const [unFormattedPhone, setUnFormattedPhone] = useState('');


  const isPhoneNumberValid = async (e) => {
    if (!formState.phone.length) return false;

    if (e.target.classList.contains('invalid-number')) return false;

    try {
      const response = await axios.create({
        baseURL: `${temboApiSite}`
      }).post('validate-phonenumber', { phone: formState.phone.replace(/[^0-9]+/g, '') });
      const formattedNumber = response.data.formatted_number;
      setFormState(Object.assign(
        {},
        formState,
        { phone: formattedNumber }
      ));
      return response.data.valid
    } catch (err) {
      return false;
    }

  }

  const modalSuccess = data => {
    submitGtagEvent(
      'sample',
      [
        country,
        formState.delivery
      ].join(' - ').toLowerCase(),
      formState.age
    )
  }

  const onClose = () => {

    setFormState({
      phone: formState.phone,
      age: 0,
      delivery: "whatsapp",
      captcha: null,
    })
  }

  const modalError = e => { }

  const onCaptchaComplete = e => {
    setFormState(x => Object.assign(formState, x, { captcha: e }))
    setBlockSubmit(false)
  }

  const handleValidation = async (e) => {
    e.preventDefault()
    const valid = await isPhoneNumberValid(e);
    setValid(valid);
    if (!valid) {
      setHasError(true);
      setShowModal(false);
      return
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowModal(true);
  }

  const handlePhoneChange = (e, data) => {
    if (hasError && !showModal) {
      setHasError(false)
    }
    const canUseSms = /(us|za)/i.test(data.countryCode)
    setCountry(data.name)
    setSmsOption(canUseSms)
    setDialCode(data.dialCode);
    setValid(false);
    setUnFormattedPhone(e);

    setFormState(y => Object.assign(
      {},
      formState,
      y,
      {
        phone: e,
        delivery: canUseSms ? 'sms' : 'whatsapp'
      }
    ))
  }

  const ageOptions = [
    { value: "7", label: "Prenatal" },
    { value: "0", label: 0 },
    { value: "1", label: 1 },
    { value: "2", label: 2 },
    { value: "3", label: 3 },
    { value: "4", label: 4 },
    { value: "5", label: 5 },
    { value: "6", label: 6 }
  ]

  const handleChange = e => {
    e.persist();
    setFormState(x => Object.assign(
      {},
      formState,
      x,
      { [e.target.name]: e.target.value }
    ))

  }

  const formPost = () => {
    setIsWhatsapp(formState.delivery === 'whatsapp')

    if (formState.delivery === 'whatsapp') {
      return new Promise(res => res(true))
    }
    const toSubmit = Object.assign(
      {},
      formState,
      { phone: formState.phone.replace(/[^0-9+]+/g, "") }
    )
    return axios.post(`${temboApiSite}/forms/sample_activity`, toSubmit)
  }


  return (
    <div className="col-xs-12 col-md-6 col-lg-6" css={ctaStyle} data-modal-active={showModal} id="sampleform">
      <form action="" method="POST" onSubmit={handleSubmit}>
        <label className="sr-only" htmlFor="inlineFormInputName2">
          Enter Your Phone Number
        </label>
        <PhoneInput
          defaultCountry="us"
          disableAreaCodes={true}
          placeholder="+1 (555) 555-5555"
          inputClass="cta-tel-input"
          dropdownClass="cta-tel-dropdown"
          onChange={handlePhoneChange}
          value={formState.phone}
          inputExtraProps={{
            name: "phone",
            value: formState.phone
          }}
        />
        {hasError && !showModal && (
          <div className="help-text text-white invalid-num">
            Please enter a valid number.
          </div>
        )}
        {valid ? (
          <button
            className="btn btn-lg shadow-lg mt-3 btn-success btn-block trigger-modal"
            type="submit"
          >
            Send Me a Sample Activity
          </button>
        ) : (
          formState.phone &&
          <button
            className="btn btn-lg shadow-lg mt-3 btn-danger btn-block trigger-modal"
            onClick={handleValidation}
          >
            Click to validate phone number
          </button>

        )
        }

      </form>
      <FormModal
        children={
          <>
            <div className="form-group">
              <label htmlFor="delivery" className="col-form-label">
                Delivery method
              </label>
              <select
                className="custom-select"
                name="delivery"
                id="delivery"
                onChange={handleChange}
                value={formState.delivery}
              >
                {["SMS", "WhatsApp"].slice(smsOption ? 0 : 1).map(x => (
                  <option
                    key={x}
                    value={x.toLowerCase()}
                  >
                    {x}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="age" className="col-form-label">
                Child age
              </label>
              <select
                className="custom-select"
                name="age"
                id="age"
                onChange={handleChange}
                value={formState.age}
              >
                {ageOptions.map(x => (
                  <option key={x.value} value={x.value}>
                    {x.label}
                  </option>
                ))}
              </select>
            </div>
          </>
        }
        formState={formState}
        submitFormSuccess={modalSuccess}
        submitFormError={modalError}
        blockSubmit={blockSubmit}
        onCaptchaComplete={onCaptchaComplete}
        showModal={showModal}
        closeModal={() => {
          setShowModal(false)
        }}
        formPost={formPost}
        onAfterClose={onClose}
        modalTitle="Get activity"
        successMessage={
          isWhatsapp ? (
            <Fragment>
              <a
                href={`https://api.whatsapp.com/send?phone=14052469432&text=SAMPLE${formState.age
                  }`}
              >
                Click here
              </a>{" "}
              to receive your sample activity on WhatsApp.
            </Fragment>
          ) : (
            <Fragment>A sample activity will be sent to you shortly.</Fragment>
          )
        }
      />
    </div>
  )
}

export default SampleForm
