import React from "react"
import { css } from "@emotion/core"
import SampleForm from './sample-form'


export default function CtaSection() {
  return (
    <section className="bg-primary py-5" id="cta">
      <div className="container">
        <div className="row mx-auto justify-content-center">
          <div className="col-xs-12 col-md-6 col-lg-4 mx-auto">
            <h2 className="text-white">Get a Free Activity</h2>
            <small className="text-white">
              <sup
                css={css`
                  margin-right: 2px;
                `}
              >
                *
              </sup>
              By signing up, you authorize Tembo Education to send your text message curriculum. Message and data rates apply..
            </small>
          </div>
        <SampleForm />
        </div>
      </div>
    </section>
  )
}
