import React from "react"
import { css } from "@emotion/core"

const stepStyle = css`
  .feature-header {
    .count-span {
      @media (max-width: 575px) {
        height: 85px;
        width: 85px;
        font-size: 60px;
      }
      font-weight: 300;
      border: 3px solid;
      width: 100px;
      border-radius: 100%;
      height: 100px;
    }
    @media (min-width: 768px) {
      font-size: 2.5rem;
    }
  }
  .feature-text {
    font-size: 1.25rem;
    letter-spacing: 0.1px;
    font-weight: 400;
    @media (min-width: 768px) {
      font-size: 1.6rem;
    }
  }
  @media (max-width: 575px) {
    display: flex;
    flex-direction: row;
    .feature-text {
      margin: auto auto auto 0;
      padding-left: 10px;
    }
  }
`

const Step = ({ text, count }) => {
  return (
    <div
      className="col-xs-10 col-sm-4 col-xl-3 mx-2 mx-sm-0 p-4"
      css={stepStyle}
    >
      <h3 className="feature-header">
        <span className="d-block display-3 text-center mx-sm-auto count-span d-flex">
          <span className="d-inline-block m-auto">{count}</span>
        </span>
      </h3>
      <div className="feature-text text-sm-center mx-3 mx-sm-0 mt-sm-2">
        {text}
      </div>
    </div>
  )
}

const steps = [
  "Receive a 15-minute activity via SMS/text or WhatsApp.",
  "Perform activity with your child.",
  "Answer a quiz based on the activity.",
]

export default () => (
  <section
    className="py-5 bg-primary text-white"
    css={css`
      overflow-x: hidden;
    `}
  >
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col text-center">
          <div className="display-text">How Tembo Works</div>
        </div>
      </div>
      <div className="row pt-4 align-items-top justify-content-center">
        {steps.map((step, i) => (
          <Step key={step} text={step} count={i + 1} css={stepStyle} />
        ))}
      </div>
    </div>
  </section>
)
